import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MerchantService } from '@core//merchant/merchant.service';
import { CustomerService } from '@core/customer/customer.service';
import { Customer } from '@core/customer/customer.types';
import { LocalStorageService } from '@core/utils/localStorage/localStorage.service';
import { Merchant } from '@core/merchant/merchant.types';
import { OrderService } from '@core/order/order.service';
import { Order } from '@core/order/order.types';
import { QartOrder } from '@core/order/qart-order.types';
import { ProductOrder } from '@core/product/product-order.types';
import { Product, ProductType, VariantItem } from '@core/product/product.types';
import { SettingsService } from '@core/settings/settings.service';
import { Settings } from '@core/settings/settings.types';
import { ShippoAddress } from '@core/shipping/address-shippo.types';
import { isEqualBasket, isEqualShippoAddress, isObjectIncludedInObject } from '@core/utils/utils';
import { FuseDrawerService } from '@fuse/components/drawer';
import { cloneDeep, isEqual } from 'lodash-es';
import { BehaviorSubject, distinctUntilChanged, Observable, skip, tap } from 'rxjs';
import { BasketItem, SessionData, SessionDataMergeOptions } from './session.type';
import { Coupon } from '@core/coupon/coupon.types';


/**
 * Service for managing the user session data.
 */
@Injectable({
  providedIn: 'root',
})
export class SessionService {

  /**
   * Enumeration of product types.
   */
  ProductType = ProductType;

  /**
   * Template for a new order.
   */
  newOrderTemplate: Order = {
    _id: null,
    merchantId: null,
    qartOrder: new QartOrder(),
    shipping: {
      shippingAddress: null,
      flat: {
        shippingLabelTrackingNumber: null
      },
      shippo: {
        parcels: [],
        shipment: null,
        shippingLabel: null,
        shippingLabelTracking: null,
        returnShipment: null,
        returnLabel: null,
        returnLabelTracking: null
      }
    },
    createdAt: new Date()
  };
  
  /** 
   * The current merchant.
  */
  private _merchant: Merchant;

  /** 
   * The current customer.
  */
  private _customer: Customer;

  /** 
   * The current settings.
   */
  private _settings: Settings;

  /** 
   * The session data.
  */
  private _data: BehaviorSubject<SessionData> = new BehaviorSubject({});
  
  /**
   * Creates an instance of SessionService.
   * @param _router The router service.
   * @param _merchantService The merchant service.
   * @param _customerService The customer service.
   * @param _orderService The order service.
   * @param _settingsService The settings service.
   * @param _fuseDrawerService The Fuse drawer service.
   * @param _localStorageService The local storage service.
   */
  constructor(
    private _router: Router,
    private _merchantService: MerchantService,
    private _customerService: CustomerService,
    private _orderService: OrderService,
    private _settingsService: SettingsService,
    private _fuseDrawerService: FuseDrawerService,
    private _localStorageService: LocalStorageService
  ) {
    //this.clear();

    // Get the merchant
    this._merchantService.merchant$
      .subscribe((merchant: Merchant) => {
        const reload: boolean = this._merchant?._id.toString() !== merchant?._id.toString();
        this._merchant = merchant;
        if (reload) {
          this._init();
        }
      });

    // Get the customer
    this._customerService.customer$
      .pipe(distinctUntilChanged())
      .subscribe((customer: Customer) => {
        this._customer = customer;
      });

    // Get the settings
    this._settingsService.settings$
      .subscribe((settings: Settings) => {
        const reload: boolean = this._settings?._id.toString() !== settings?._id.toString();
        this._settings = settings;
        if (reload) {
          this._init();
        }
      });
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Returns an observable that emits the current session data.
   * @returns An observable that emits the current session data.
   */
  get data$(): Observable<SessionData> {
    return this._data.asObservable();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Determines whether a transaction is possible based on the session data.
   * @param data - The session data to check.
   * @returns True if a transaction is possible, false otherwise.
   */
  private _isTransactionPossible(data: SessionData): boolean {
    const hasItemsInBasket: boolean = data.basket.qartOrder?.productOrders?.length > 0;
    const hasShippableProducts: boolean = this._orderService.isShippableOrder(data.basket); // Contains at least one product that is shippable
    const isShippingEnabled: boolean = this._settings?.shippingEnabled ?? false; // If the settings are not loaded yet, we default to false (it will be run again once the settings is loaded).
    const isShippingRequested: boolean = data.deliveryMethod === 'delivery';
    const hasShippingAddress: boolean = data.shipping?.address != null;
    const hasShippingRate: boolean = data.shipping?.selectedRate != null;
    const isFlatRateShippingRequested: boolean = data.shipping?.rateType === 'flat';
    const isShippoRateShippingRequested: boolean = data.shipping?.rateType === 'shippo';
    const isPickupRequested: boolean = data.deliveryMethod === 'collect';
    const hasParcels: boolean = data.shipping?.parcels?.length > 0;
    const hasShipment: boolean = data.shipping?.shipment != null;
    const hasReturnShipment: boolean = data.shipping?.returnShipment != null;
    // Check that all the conditions are met if pickup was requested
    const pickupOK = isPickupRequested 
      && hasItemsInBasket; 
    // Check that all the conditions are met if shipping was requested
    const shippingOK = isShippingRequested 
      && hasItemsInBasket
      && hasShippableProducts
      && isShippingEnabled
      && hasShippingAddress 
      && hasShippingRate;
    // Check that all the conditions are met if shipping was requested with flat rate
    const flatRateDeliveryOK = shippingOK
      && isFlatRateShippingRequested;
    // Check that all the conditions are met if shipping was requested with shippo
    const shippoRateDeliveryOK = shippingOK
      && isShippoRateShippingRequested 
      && hasParcels
      && hasShipment
      && hasReturnShipment;
    return pickupOK || flatRateDeliveryOK || shippoRateDeliveryOK;
  }

  /**
   * Rebuilds the session data with default values for missing properties.
   * @param data The session data to rebuild.
   * @returns The rebuilt session data.
   */
  private _rebuildFormat(data: SessionData): SessionData {
    if (data.basket) {
      data.basket = this._orderService.rebuildOrder(data.basket);
    }
    if (!data.deliveryMethod) {
      data['deliveryMethod'] = 'collect';
    }
    if (!data.shipping) {
      data['shipping'] = {
        address: null,
        rateType: null,
        selectedRate: null,
        parcels: [],
        shipment: null,
        returnShipment: null,
        allParcels: {},
        allRates: {}
      };
    }
    if (!data.payment) {
      data['payment'] = {
        method: null,
        intent: null,
        response: null
      };
    }
    if (!data.booking) {
      data['booking'] = {
        requestedPage: null,
        comingFromPage: null,
        selectedServices: [],
        basketItems: {},
        canBeProvidedBy: [],
        providedBy: null,
        date: null,
        time: null
    };
    }
    if (!data.extras) {
      data['extras'] = {
        isShippable: false,
        hasMixedBasket: false,
        hasOnlyServices: false,
        hasOnlyProducts: false,
        estimatedDeliveryDate: null,
        isTransactionPossible: false
      };
    }
    return data;
  }

  /**
   * Ensures consistency of the session data by resetting selected address and shipping options if needed.
   * @param newData The new session data.
   * @param previousData The previous session data.
   * @param patchData The patch session data.
   * @returns The updated session data.
   */
  private _ensureConsistency(newData: SessionData, previousData: SessionData, patchData: SessionData): SessionData {

    const response: SessionData = cloneDeep(newData);

    // Reset the selected address in the session data, if no customer is logged in or if the customer does not have this address
    if (response.shipping?.address) {
      if (!this._customer) {
        response.shipping.address = null;
      } else if (!this._customer.shippoAddresses?.length) {
        response.shipping.address = null;
      } else if (!this._customer.shippoAddresses.find((address: ShippoAddress) => isEqualShippoAddress(address, response.shipping?.address))) {
        response.shipping.address = null;
      }
    }
    
    // Reset the selected shipping options in the session data, if needed
    let hasBasketChanged: boolean;
    if (!patchData.basket || !previousData.basket) {
      hasBasketChanged = false;
    } else {
      hasBasketChanged = !isEqualBasket(previousData.basket, patchData.basket);
    }
    const hasDeliveryMethodChanged = (patchData.deliveryMethod && patchData.deliveryMethod !== previousData.deliveryMethod) ?? false;
    const hasAddressChanged = (previousData.shipping?.address && patchData.shipping?.address && !isEqualShippoAddress(patchData.shipping?.address, previousData.shipping?.address)) ?? false; // The first condition makes sure that not both addresses are null or undefined.
    const isShippingEnabled = this._settings?.shippingEnabled ?? true; // If the settings are not loaded yet, we default to true so that we don't delete anything (it will be run again once the settings is loaded).
    if (hasDeliveryMethodChanged || hasAddressChanged || !isShippingEnabled || hasBasketChanged) {
      response.shipping.parcels = null;
      response.shipping.rateType = null;
      response.shipping.returnShipment = null;
      response.shipping.shipment = null;
      response.shipping.selectedRate = null;
      
      // If the shipping is no longer allowed, we also need to reset the delivery method
      if (!isShippingEnabled) {
        response.deliveryMethod = 'collect';
      }
    }

    // Reset the selected shipping options in the basket data, if needed
    if (response.basket.qartOrder.productOrders.length === 0 && response.basket.qartOrder.deliveryMethod === 'delivery') {
      response.basket.qartOrder.deliveryMethod = 'collect';
      response.basket.qartOrder.shippingMethod = null;
      response.basket.qartOrder.shippoShippingRate = null;
      response.basket.qartOrder.flatShippingRate = null;
      if (response.basket.shipping?.shippo?.parcels) {
        response.basket.shipping.shippo.parcels = [];
      }
      if (response.basket.shipping?.shippo?.shipment) {
        response.basket.shipping.shippo.shipment = null;
      }
      if (response.basket.shipping?.shippo?.returnShipment) {
        response.basket.shipping.shippo.returnShipment = null;
      }
    }

    // Remove the coupon if it no longer applies
    if (response.basket.qartOrder?.coupon && !this._orderService.doesCouponApplies(response.basket, response.basket.qartOrder?.coupon)) {
      response.basket.qartOrder.coupon = null;
    }

    return response;
  }

  /**
   * Computes the extras for the session data.
   * @param newData The new session data.
   * @param previousData The previous session data.
   * @param patchData The patch session data.
   * @returns The session data with computed extras.
   */
  private _computeExtras(newData: SessionData, previousData: SessionData, patchData: SessionData): SessionData {

    let response: SessionData = cloneDeep(newData);

    // Make sure the data is in the right format
    response = this._rebuildFormat(response);

    // Compute the extras, when applicable
    if (response.basket) {
      response.extras.isShippable = this._orderService.isShippableOrder(response.basket); // Contains at least one product that is shippable
      response.extras.hasMixedBasket = this._orderService.hasMixedBasket(response.basket);
      response.extras.hasOnlyServices = this._orderService.hasOnlyServices(response.basket);
      response.extras.hasOnlyProducts = this._orderService.hasOnlyProducts(response.basket);
      response.extras.isTransactionPossible = this._isTransactionPossible(response);
      if (this._settings && response.shipping.selectedRate) {
        response.extras.estimatedDeliveryDate = this._orderService.computeEstimatedDeliveryDate(response.basket, this._settings, response.shipping.selectedRate);
      }
    }
    return response;
  }

  /**
   * Replaces the properties of an object with the properties of another object.
   * @param obj - The object to replace the properties of.
   * @param val - The object containing the new properties.
   * @returns The updated object with the replaced properties.
   */
  private _flatReplace(obj: any, val: any): any {
    Object.entries(val).forEach(([k, v]) => {
      obj[k] = cloneDeep(v);
    });
    return obj;
  }

  /**
   * Recursively merges two objects.
   * @param obj - The object to merge into.
   * @param val - The object to merge from.
   * @returns The merged object.
   */
  private _recursiveMerge(obj: any, val: any): any {
    Object.entries(val).forEach(([k, v]) => {
      if (Array.isArray(v)) {
        obj[k] = cloneDeep(v);
      } else if (typeof v === 'object' && v !== null) {
        obj[k] = this._recursiveMerge(obj[k] || {}, v);
      } 
      else {
        obj[k] = cloneDeep(v);
      }
    });
    return obj;
  }

  /**
   * Saves the session data to local storage and emits the new data to subscribers.
   * @param data - The session data to be saved.
   * @returns void
   */
  private _saveData(data: SessionData): void {
    if (!this._merchant) {
      return;
    }
    this._localStorageService.setItem(`session_${this._merchant._id}`, JSON.stringify(data));
    this._data.next(data);
  }

  /**
   * Initializes the session data by loading it from local storage and rebuilding it if necessary.
   * @returns {void}
   */
  private _init(): void {
    if (!this._merchant || !this._settings) {
      return;
    }
    // Load the session data from the local storage
    const rawData: string = this._localStorageService.getItem(`session_${this._merchant._id}`);
    let savedData: SessionData;
    if (rawData) {
      savedData = JSON.parse(rawData);
    }
    // Restore the basket content, if needed
    let data: SessionData = {};
    if (savedData?.basket) {
      data.basket = this._orderService.rebuildOrder(cloneDeep(savedData.basket));
    } else {
      data.basket = cloneDeep(this.newOrderTemplate);
    }
    // Make sure the data is in the right format
    data = this._rebuildFormat(data);
    // Make sure that the new data is consistent
    data = this._ensureConsistency(data, data, data);
    // Update the extra values
    data = this._computeExtras(data, data, data);
    this._saveData(data);
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Updates the session data with the provided patch data.
   * @param patchData - The data to patch the session with.
   * @param options - The options for merging the patch data with the current data.
   * @returns void
   */
  patch(patchData: SessionData, options?: SessionDataMergeOptions): void {
    if (!options) {
      options = { mode: 'merge' };
    }
    // Get the current data
    let currentData: SessionData = cloneDeep(this._data.getValue());
    // Check that the patch actually differs from the current values
    if (options.mode === 'merge' && isObjectIncludedInObject(patchData, currentData)) {
      return;
    }
    // Make sure the data is in the right format
    currentData = this._rebuildFormat(currentData);
    // Make sure that the current data is consistent
    currentData = this._ensureConsistency(currentData, currentData, patchData);
    // Create the new data from the current data
    let newData: SessionData = cloneDeep(currentData);
    if (options.mode === 'merge') {
      // Merge the patch data with the current data
      newData = this._recursiveMerge(newData, patchData);
    } else if (options.mode === 'replace') {
      // Replace the the current data with the patch data
      newData = this._flatReplace(newData, patchData);
    }
    // Make sure the data is in the right format
    newData = this._rebuildFormat(newData);
    // Make sure that the new data is consistent
    newData = this._ensureConsistency(newData, currentData, patchData);
    // Update the extra values
    newData = this._computeExtras(newData, currentData, patchData);
    // Save the new data
    this._saveData(newData);
  }

  /**
   * Clears the session data for the current merchant.
   * Removes the session data from local storage and sets a new session data with an empty basket.
   * @returns void
   */
  clear(): void {
    this._localStorageService.removeItem(`session_${this._merchant._id}`);
    let newData: SessionData = {
      basket: cloneDeep(this.newOrderTemplate)
    };
    // Make sure the data is in the right format
    newData = this._rebuildFormat(newData);
    // Make sure that the new data is consistent
    newData = this._ensureConsistency(newData, newData, newData);
    // Update the extra values
    newData = this._computeExtras(newData, newData, newData);
    // Save the new data
    this._localStorageService.setItem(`session_${this._merchant._id}`, JSON.stringify(newData));
    this._data.next(newData);
  }
  
  /**
   * Updates the session data to only keep the basket and saves it to local storage.
   * @returns void
   */
  keepBasketOnly(): void {
    const data = this._data.getValue();
    let newData: SessionData = {
      basket: cloneDeep(data.basket)
    };
    // Make sure the data is in the right format
    newData = this._rebuildFormat(newData);
    // Make sure that the new data is consistent
    newData = this._ensureConsistency(newData, newData, newData);
    // Update the extra values
    newData = this._computeExtras(newData, newData, newData);
    // Save the new data
    this._localStorageService.setItem(`session_${this._merchant._id}`, JSON.stringify(newData));
    this._data.next(newData);
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Basket-related methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Adds a product to the basket with the specified item details.
   * @param product The product to add to the basket.
   * @param item The item details for the product to add to the basket.
   */
  addProductToBasket(product: Product, item: BasketItem): void {
    const data = this._data.getValue();
    const order = cloneDeep(data?.basket);
    if (!order) {
      return;
    }
    // Get the selected variant
    let selectedVariantItem: VariantItem;
    if (product.hasVariants) {
        const selectedOptions = item.attributes;
        selectedVariantItem = product.variantItems.find(variantItem => JSON.stringify(variantItem.attributes.map(a => a.option)) === JSON.stringify(selectedOptions));
    } else {
        selectedVariantItem = product.variantItems[0];
    }
    // Work with a local copy of the selected variant item
    selectedVariantItem = cloneDeep(selectedVariantItem);
    // Attached the selected custom field values to the variant item
    selectedVariantItem.customFieldValues = cloneDeep(item.customFields);
    // Verify that the item is a product and is not already in the basket
    let alreadyInBasket = false;
    let existingProductOrder: ProductOrder;
    let existingProductOrders: ProductOrder[];
    let existingProductOrderIndex: number = -1;
    let existingVariantItem: VariantItem;
    if (product.type !== ProductType.Product) {
      // If it is a service then a new product order must be created
      alreadyInBasket = false;
    } else {
      // Get all the product orders for that product
      existingProductOrders = order.qartOrder.productOrders.filter(po => po.product._id === product._id);
      // If there are no product orders for that product, then the item is not in the basket
      if (existingProductOrders.length === 0) {
        alreadyInBasket = false;
      } else {
        // If there are product orders for that product, then check if the selected variant item is already in the basket
        existingProductOrderIndex = existingProductOrders.findIndex(po => isEqual(po.selectedVariant.attributes.map(a => a.option), selectedVariantItem.attributes.map(a => a.option))
            && isEqual(po.selectedVariant.customFieldValues, selectedVariantItem.customFieldValues));
        if (existingProductOrderIndex === -1) {
          alreadyInBasket = false;
        } else {
          alreadyInBasket = true;
          existingProductOrder = existingProductOrders[existingProductOrderIndex];
          // Make a deep copy of the object (otherwise the subscriber won't see a change in the object)
          existingProductOrder = cloneDeep(existingProductOrder);
          existingVariantItem = existingProductOrder.selectedVariant;
        }
      }
    }
    if (alreadyInBasket) {
      // Add the quantity to the existing product order
      existingProductOrder.quantity += item.quantity;
      // Verify that the quantity is not greater than the available quantity (if stock is limited)
      if (!existingVariantItem.unlimitedQuantity && existingProductOrder.quantity > existingVariantItem.quantity) {
        existingProductOrder.quantity = existingVariantItem.quantity;
      }
      order.qartOrder.productOrders[existingProductOrderIndex] = existingProductOrder;
    } else {
      // Create the new product order
      const productOrder = new ProductOrder(product);
      productOrder.selectedVariant = cloneDeep(selectedVariantItem);
      productOrder.quantity = item.quantity;
      productOrder.selectedEmployee = item.employee;
      if (item.time) {
        productOrder.availableEmployees = item.time.employees?.map(e => e.id) ?? [];
        productOrder.selectedServiceTimeSlot = item.time.time;
      }
      order.qartOrder.productOrders.push(productOrder);
    }
    // reset the pickup date
    order.qartOrder.pickupDate = null;
    this.patch({
      basket: order
    });
    // Send the event through GTM
    if (window && window['dataLayer']) {
      window['dataLayer']?.push({
        event: 'add_to_basket'
      });
    }
  }

  /**
   * Updates a product in the basket with the given product order ID and basket item.
   * @param productOrderId The ID of the product order to update.
   * @param item The basket item to update the product with.
   * @returns void
   */
  updateProductInBasket(productOrderId: number, item: BasketItem): void {
    const data = this._data.getValue();
    const order = cloneDeep(data?.basket);
    if (!order) {
      return;
    }
    // Get the product order to replace
    const existingProductOrderIndex: number = order.qartOrder.productOrders.findIndex(po => po.id === productOrderId);
    if (existingProductOrderIndex === -1) {
      // If the product order is not found then there is a problem
      return;
    }
    // Retrieve the product order
    const existingProductOrder: ProductOrder = order.qartOrder.productOrders[existingProductOrderIndex];
    // Make a deep copy of the object (otherwise the subscriber won't see a change in the object)
    const newProductOrder: ProductOrder = cloneDeep(existingProductOrder);
    // Get the selected variant
    let selectedVariantItem: VariantItem;
    if (newProductOrder.product.hasVariants) {
      const selectedOptions = item.attributes;
      selectedVariantItem = newProductOrder.product.variantItems.find(variantItem => JSON.stringify(variantItem.attributes.map(a => a.option)) === JSON.stringify(selectedOptions));
    } else {
      selectedVariantItem = newProductOrder.product.variantItems[0];
    }
    // Create the new product order
    newProductOrder.selectedVariant = cloneDeep(selectedVariantItem);
    newProductOrder.selectedVariant.customFieldValues = cloneDeep(item.customFields);
    newProductOrder.quantity = item.quantity;
    newProductOrder.selectedEmployee = item.employee;
    if (item.time) {
      newProductOrder.availableEmployees = item.time.employees?.map(e => e.id) ?? [];
      newProductOrder.selectedServiceTimeSlot = item.time.time;
    }
    order.qartOrder.productOrders[existingProductOrderIndex] = newProductOrder;
    // reset the pickup date
    order.qartOrder.pickupDate = null;
    this.patch({
      basket: order
    });
  }
  
  /**
   * Adds a product to the basket with a quantity of 1 and opens the basket drawer.
   * @param product - The product to add to the basket.
   * @returns void
   */
  quickAddProductToBasket(product: Product): void {
    const data = this._data.getValue();
    const order = cloneDeep(data?.basket);
    if (!order) {
      return;
    }
    if (product.type !== ProductType.Product 
      || product.hasVariants 
      || (!product.variantItems[0].unlimitedQuantity && product.variantItems[0].quantity === 0)) {
        return; 
    }
    this.addProductToBasket(product, {
      quantity: 1,
      attributes: [],
      customFields: [],
      employee: null,
      date: null,
      time: {
        employees: [],
        time: null,
        endTime: null
      }
    });
    this._fuseDrawerService.getComponent('basket').open();
  }

  /**
   * Updates the basket with the given order.
   * @param order - The order to update the basket with.
   */
  updateBasket(order: Order): void {
    this.patch({
      basket: order
    });
  }
  
  /**
   * Removes a product from the basket.
   * @param productOrderId - The ID of the product order to remove.
   * @returns void
   */
  removeProductFromBasket(productOrderId: number): void {
    const data = this._data.getValue();
    const order = cloneDeep(data?.basket);
    if (!order) {
      return;
    }
    order.qartOrder.productOrders = order.qartOrder.productOrders.filter(productOrder => productOrder.id !== productOrderId);
    const update: any = {
      basket: order
    };
    if (order.qartOrder.productOrders.length === 0) {
      update['deliveryMethod'] = 'collect';
    }
    this.patch(update);
  }

  /**
   * Edits a product in the basket and navigates to the product page with the product order ID as a query parameter.
   * @param productOrderId The ID of the product order to edit.
   * @returns void
   */
  editProductInBasket(productOrderId: number): void {
    const data = this._data.getValue();
    const order = cloneDeep(data?.basket);
    if (!order) {
      return;
    }
    const productOrder: ProductOrder = order.qartOrder.productOrders.find(po => po.id === productOrderId);
    if (!productOrder) {
      return;
    }
    this._router.navigate([`/products/${productOrder.product.SEO.uri}`], { 
      queryParams: {
        productOrderId
      }
    });
  }

}
