import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MerchantService } from '@core//merchant/merchant.service';
import { CacheService } from '@core/cache/cache.service';
import { Coupon } from '@core/coupon/coupon.types';
import { Merchant } from '@core/merchant/merchant.types';
import { Order } from '@core/order/order.types';
import { QartOrder } from '@core/order/qart-order.types';
import { ProductOrder } from '@core/product/product-order.types';
import { Product, ProductType } from '@core/product/product.types';
import { Settings } from '@core/settings/settings.types';
import { FlatShippingRate } from '@core/shipping/shipping-rate-flat.types';
import { ShippoShippingRate } from '@core/shipping/shipping-rate-shippo.types';
import { UserService } from '@core/user/user.service';
import { EnvironmentService } from '@core/utils/environment/environment.service';
import { DEFAULT_HTTP_OPTIONS_NO_EXPIRE, HttpOptions } from '@core/utils/http-options.types';
import { environment } from '@env/environment';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { TranslocoService } from '@jsverse/transloco';
import { PaymentIntent } from '@stripe/stripe-js';
import { User as FirebaseUser } from 'firebase/auth';
import { cloneDeep } from 'lodash-es';
import moment from 'moment';
import { BehaviorSubject, catchError, map, Observable, of, tap } from 'rxjs';


/**
 * Service for managing orders.
 */
@Injectable({
  providedIn: 'root'
})
export class OrderService {

  /**
   * The merchant associated with the orders.
   */
  private _merchant: Merchant;

  /**
   * The user associated with the orders.
   */
  private _user: FirebaseUser | null = null;

  /**
   * The currently selected order.
   */
  private _order: BehaviorSubject<Order | null> = new BehaviorSubject(null);

  /**
     * The transloco read key for translations.
     */
  private _translocoRead: string = 'services.order';

  /**
   * The number of orders.
   */
  private _orderCount: BehaviorSubject<number> = new BehaviorSubject(0);

  /**
   * The list of orders.
   */
  private _orders: BehaviorSubject<Order[]> = new BehaviorSubject([]);

  /**
   * The cache namespace.
   */
  private _cacheNamespace: string = 'order';

  /**
    * Creates an instance of OrderService.
    * @param {EnvironmentService} _environmentService - The environment service.
    * @param {MerchantService} _merchantService - The merchant service.
    * @param {UserService} _userService - The user service.
    * @param {FuseConfirmationService} _fuseConfirmationService - The confirmation service.
    * @param {TranslocoService} _translocoService - The transloco service.
    * @param {CacheService} _cacheService - The cache service.
    * @param {HttpClient} _httpClient - The HTTP client.
    * @memberof OrderService
    */
  constructor(
    private _environmentService: EnvironmentService,
    private _merchantService: MerchantService,
    private _userService: UserService,
    private _fuseConfirmationService: FuseConfirmationService,
    private _translocoService: TranslocoService,
    private _cacheService: CacheService,
    private _httpClient: HttpClient
  ) {

    // Get the merchant
    this._merchantService.merchant$
      .subscribe((merchant: Merchant) => {
        const reload: boolean = this._merchant?._id.toString() !== merchant?._id.toString();
        this._merchant = merchant;
        if (reload) {
          this._reset();
        }
      });

    // Get the user
    this._userService.user$
      .subscribe((user: FirebaseUser | null) => {
        const reload: boolean = this._user?.uid.toString() !== user?.uid.toString();
        this._user = user;
        if (reload) {
          this._reset();
        }
      });

  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Returns an observable of the current orders.
   * @returns An observable of the current orders.
   */
  get orders$(): Observable<Order[]> {
    return this._orders.asObservable();
  }

  /**
   * Returns an observable of the current order or null if there is no order.
   * @returns An observable of the current order or null if there is no order.
   */
  get order$(): Observable<Order | null> {
    return this._order.asObservable();
  }

  /**
   * Setter method to update the current order.
   * @param order The new order to set.
   */
  set order(order: Order | null) {
    this._order.next(order);
  }

  /**
   * Returns an observable of the current order count.
   * @returns An observable of the current order count.
   */
  get orderCount$(): Observable<number> {
    return this._orderCount.asObservable();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Resets the order service state by setting all private BehaviorSubjects to their initial values.
   * @returns void
   */
  private _reset(): void {
    this._order.next(null);
    this._orderCount.next(0);
    this._orders.next([]);
  }

  /**
   * Finds and replaces an order in the list of orders.
   * @param order - The order to replace.
   */
  private _findAndReplace(order: Order): void {
    const orders: Order[] = this._orders.getValue();
    const orderIndex = orders.findIndex(p => p._id === order._id);
    if (orderIndex !== -1) {
      orders[orderIndex] = order;
      this._orders.next(orders);
    }
  }

  /**
   * Returns the minimum available date for a product based on the given date, product availability, and settings.
   * @param date - The date to start searching for the minimum available date.
   * @param product - The product to check availability for.
   * @param settings - The settings to use for timezone information.
   * @returns The minimum available date for the product, or null if there is no available date after the given date.
   */
  private _getMinimumAvailableDateFrom(date: moment.Moment, product: Product, settings: Settings): moment.Moment {
    const userTimezone = moment.tz.guess(false);
    if (product.availability.type === 'specific') {
      let productAvailableDates = product.availability.times.map(time => moment(time).tz(settings.timezone).tz(userTimezone, true));
      const dateFilter = moment(date).startOf('day').tz(settings.timezone).tz(userTimezone, true);
      productAvailableDates = productAvailableDates.filter(d => !moment(d).isBefore(dateFilter));
      let minAvailableDate: moment.Moment = moment.min(productAvailableDates);
      if (minAvailableDate.isSame(dateFilter)) {
        return moment(date);
      } else if (minAvailableDate.isBefore(dateFilter)) {
        return null;
      } else {
        return moment(minAvailableDate);
      }
    } else if (product.availability.type === 'repeat') {
      if (product.availability.period === 'yearly') {
        if (product.availability.repeat.map(month => month.id).includes(date.month())) {
          // If the product is available on that month, do nothing
        } else if (product.availability.repeat.map(month => month.id).filter(month => month > date.month()).length > 0) {
          // If there is a later month at which the product is available, then return the soonest date of that month
          const nextMonth: number = Math.min(...product.availability.repeat.map(month => month.id).filter(month => month > date.month()));
          date.set('month', nextMonth);
          date = moment(date).startOf('month');
        } else {
          // If there is no later month at which the product is available, then return the soonest date of next year
          const firstMonth: number = Math.min(...product.availability.repeat.map(month => month.id));
          date.set('month', firstMonth);
          date.set('year', date.year() + 1);
          date = moment(date).startOf('month');
        }
      } else if (product.availability.period === 'monthly') {
        if (product.availability.repeat.map(day => day.id).includes(date.date())) {
          // If the product is available on that day, do nothing
        } else if (product.availability.repeat.map(day => day.id).filter(day => day > date.date()).length > 0) {
          // If there is a later day at which the product is available, then return the soonest day
          const nextDay: number = Math.min(...product.availability.repeat.map(day => day.id).filter(day => day > date.date()));
          date.set('day', nextDay);
          date = moment(date).startOf('day');
        } else {
          // If there is no later day at which the product is available, then return the soonest day of next month
          const firstDay: number = Math.min(...product.availability.repeat.map(day => day.id));
          date.set('day', firstDay);
          date.set('month', date.month() + 1);
          date = moment(date).startOf('day');
        }
      } else if (product.availability.period === 'weekly') {
        if (product.availability.repeat.map(day => day.id).includes(date.day())) {
          // If the product is available on that day, do nothing
        } else if (product.availability.repeat.map(day => day.id).filter(day => day > date.day()).length > 0) {
          // If there is a later day at which the product is available, then return the soonest day
          const nextDay: number = Math.min(...product.availability.repeat.map(day => day.id).filter(day => day > date.day()));
          date.day(nextDay);
          date = moment(date).startOf('day');
        } else {
          // If there is no later day at which the product is available, then return the soonest day of next week
          const firstDay: number = Math.min(...product.availability.repeat.map(day => day.id));
          date.day(firstDay);
          date = date.add(1, 'weeks');
          date = moment(date).startOf('day');
        }
      } else if (product.availability.period === 'daily') {
        if (product.availability.repeat.map(hour => hour.id).includes(date.hours())) {
          // If the product is available on that hour, do nothing
        } else if (product.availability.repeat.map(hour => hour.id).filter(hour => hour > date.hours()).length > 0) {
          // If there is a later hour at which the product is available, then return the soonest hour
          const nextHour: number = Math.min(...product.availability.repeat.map(hour => hour.id).filter(hour => hour > date.hours()));
          date.set('hour', nextHour);
          date = moment(date).startOf('hour');
        } else {
          // If there is no later day at which the product is available, then return the soonest day of next month
          const firsthour: number = Math.min(...product.availability.repeat.map(hour => hour.id));
          date.set('hour', firsthour);
          date.set('day', date.date() + 1);
          date = moment(date).startOf('day');
        }
      }
    }
    return date;
  }

  /**
   * Converts a time slot string to a number representing the hour or minute component.
   * @param timeSlot - The time slot string to convert (e.g. "10:30 am").
   * @param component - The component to extract from the time slot string. Must be either "h" for hour or "m" for minute.
   * @returns A number representing the hour or minute component of the time slot string, or null if the time slot string is invalid.
   */
  private _getConvertedTime(timeSlot: string, component: string): number {
    let time = timeSlot.match(/^(0?\d|1[0-2])(:)([0-5]\d) (am|pm)$/);
    if (time && time.length == 5) {
      if (component === 'm') {
        return parseInt(time[3]);
      } else if (component === 'h') {
        return time[4] === "pm" ? parseInt(time[1]) + 12 : parseInt(time[1]);
      }
    }
    return null;
  }

  /**
   * Returns the minimum opening hour from the given date based on the merchant's opening hours.
   * If the merchant is online only, then it assumes the merchant is open 24/7.
   * @param date - The date to get the minimum opening hour from.
   * @returns The minimum opening hour as a moment object or null if the merchant is closed all day.
   */
  private _getMinimumOpeningHourFrom(date: moment.Moment): moment.Moment {
    // If the business is only online, then we ignore its opening hours and assume it is open 24/7
    if (this._merchant.onlineOnly) {
      return moment(date);
    }
    const DayToIndexMapping = {
      'Sunday': 0,
      'Monday': 1,
      'Tuesday': 2,
      'Wednesday': 3,
      'Thursday': 4,
      'Friday': 5,
      'Saturday': 6
    };
    // Get all the slots from each day of the week
    let openDays = this._merchant.openingHours.days.map(day => ({
      id: DayToIndexMapping[day.name],
      open: day.open,
      slots: day.slots.map(slot => ({
        from: {
          hours: this._getConvertedTime(slot.from.selected, 'h'),
          minutes: this._getConvertedTime(slot.from.selected, 'm'),
        },
        to: {
          hours: this._getConvertedTime(slot.to.selected, 'h'),
          minutes: this._getConvertedTime(slot.to.selected, 'm'),
        },
      }))
    }));
    // Get the input weekday
    const openDayIdx = openDays.findIndex(day => day.id === date.day());
    // Reorder the openDays starting from the input weekday
    openDays = openDays.slice(openDayIdx).concat(openDays.slice(0, openDayIdx));
    // Iterate through each day of the week (also includes getting back to the same day in one week)
    for (let idx = 0; idx <= openDays.length; idx++) {
      const currentOpenDay = openDays[idx % openDays.length];
      if (currentOpenDay.open) {
        // If this is the day of the input date, then search for a time-slot
        if (idx === 0) {
          for (let slot of currentOpenDay.slots) {
            let start: moment.Moment = moment(date);
            start.set({
              hour: slot.from.hours,
              minute: slot.from.minutes,
              second: 0,
              millisecond: 0
            });
            let end: moment.Moment = moment(date);
            end.set({
              hour: slot.to.hours,
              minute: slot.to.minutes,
              second: 0,
              millisecond: 0
            });
            if (date.isBefore(end)) {
              return moment.max(start, date);
            }
          }
        } else {
          // Otherwise, take the first available time of this following day
          const firstSlot = currentOpenDay.slots[0];
          let start: moment.Moment = moment(date).add(idx, 'days');
          start.set({
            hour: firstSlot.from.hours,
            minute: firstSlot.from.minutes,
            second: 0,
            millisecond: 0
          });
          return start;
        }
      }
    }
    return null;
  }

  /**
   * Returns the minimum available date for pickup based on the product availability and shop opening hours.
   * @param minimumPickupDate - The minimum date for pickup.
   * @param products - The list of products to check availability for.
   * @param settings - The settings object containing shop opening hours.
   * @returns The minimum available date for pickup.
   */
  private _getMinimumAvailableDate(minimumPickupDate: moment.Moment, products: Product[], settings: Settings): moment.Moment {
    // Take the availability and shop opening hours into account
    //console.log("BEFORE minimumPickupDate", minimumPickupDate);
    for (let product of products) {
      //console.log("minimumPickupDate", minimumPickupDate);
      // Iterate while the minimum date from the product availability 
      // and the minimum date from the opening hours differ
      let maxIter = 50;
      let iter = 0;
      let prevMinimumPickupDate: moment.Moment;
      do {
        prevMinimumPickupDate = moment(minimumPickupDate);
        minimumPickupDate = this._getMinimumAvailableDateFrom(moment(minimumPickupDate), product, settings);
        //console.log("minimumPickupDate from availability", minimumPickupDate);
        if (minimumPickupDate === null) {
          // There is NO possible pickup date!
          console.log("1) There is NO possible pickup date!");
          return null;
        }
        if (!this._merchant.onlineOnly) {
          minimumPickupDate = this._getMinimumOpeningHourFrom(moment(minimumPickupDate));
        }
        //console.log("minimumPickupDate from opening hours", minimumPickupDate);
        if (minimumPickupDate === null) {
          // There is NO possible pickup date!
          //console.log("2) There is NO possible pickup date!");
          minimumPickupDate = null;
          break;
        }
        iter += 1;
      } while (iter < maxIter && !minimumPickupDate.isSame(prevMinimumPickupDate));
      if (iter >= maxIter || minimumPickupDate === null) {
        minimumPickupDate = null;
        break;
      }
    }
    return minimumPickupDate;
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Rebuilds an order by creating a new QartOrder instance and converting refund created dates to Date objects.
   * @param order - The order to rebuild.
   * @returns The rebuilt order.
   */
  rebuildOrder(order: Order): Order {
    const qartOrder = new QartOrder(order.qartOrder);
    order.qartOrder = qartOrder;
    order.refunds?.forEach((refund: any) => refund.created = new Date(refund.created * 1000));
    const conditions: any[] = order.qartOrder.flatShippingRate?.conditions ?? [];
    if (conditions?.length > 0) {
      for (const condition of conditions) {
        if (condition?.ranges?.length > 0) {
          for (const range of condition.ranges) {
            if (range.max == null) {
              range.max = Infinity;
            }
          }
        }
      }
    }
    if (!order.refunds) {
      order.refunds = [];
    }
    return order;
  }

  /**
   * Check if a coupon applies to the basket.
   * @param order - The order to check.
   * @param coupon - The coupon to check.
   * @returns void
   */
  doesCouponApplies(order: Order, coupon: Coupon): boolean {
    if (!coupon) {
      return true;
    }
    if (!coupon.condition) {
      return true;
    }
    if (coupon.condition.criteria === 'totalBasket') {
      return order.qartOrder.subtotal >= coupon.condition.threshold;
    } else if (coupon.condition.criteria === 'nbItemsInBaskets') {
      return order.qartOrder.numberOfProducts >= coupon.condition.threshold;
    }
    return false;
  }

  /**
   * Retrieves an order by its ID.
   * @param orderId - The ID of the order to retrieve.
   * @param options - The options related to cache and propagation.
   * @returns The requested order object.
   */
  getOrder(orderId: string, options: HttpOptions=DEFAULT_HTTP_OPTIONS_NO_EXPIRE): Observable<Order | null> {
    if (!this._merchant) {
      console.log('Error: No merchant.');
      return of(null);
    }
    // If the order is already loaded, return it.
    // If the order is not found, do not record the cache miss yet.
    const cacheParams: any = { merchantId: this._merchant._id, orderId };
    if (options.cache) {
      if (this._cacheService.has(this._cacheNamespace, 'order', cacheParams, false)) {
        this._cacheService.updateStats(true);
        const order: Order = this._cacheService.get(this._cacheNamespace, 'order', cacheParams);
        if (options.forcePropagate) {
          this._order.next(order);
        }
        return of(order);
      }
      // Check if the order is one of the previously loaded sets of orders
      const orderSets: Order[][] = this._cacheService.get(this._cacheNamespace, 'orders', null);
      if (orderSets) {
        for (const orders of orderSets) {
          const order: Order = orders.find(o => o._id === orderId);
          if (order) {
            if (options.cache) {
              this._cacheService.set(this._cacheNamespace, 'order', cacheParams, order, options.expire);
            }
            if (options.propagate) {
              this._order.next(order);
            }
            // Record the cache hit.
            this._cacheService.updateStats(true);
            return of(order);
          }
        }
      }
      // Record the cache miss.
      this._cacheService.updateStats(false);
    }
    // Otherwise, load the product from the backend
    return this._httpClient.get<Order>(
      `${this._environmentService.getApiUrl()}/${environment.qart.apiVersion}/merchants/${this._merchant._id}/orders/${orderId}`,
      {
        headers: new HttpHeaders().set('Content-Type', 'application/json'),
        withCredentials: true
      })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          console.error(`Error: ${error}`);
          return of(null);
        }),
        map((order: Order) => order ? this.rebuildOrder(order) : null),
        // Handle the logic of the cache and the propagation
        tap((order: Order) => {
          if (options.cache) {
            this._cacheService.set(this._cacheNamespace, 'order', cacheParams, order, options.expire);
          }
          if (options.propagate) {
            this._order.next(order);
          }
        })
      );
  }

  /**
   * Retrieves a list of orders based on the provided filters.
   * @param filters - An object containing filters to apply to the search.
   * @param options - The options related to cache and propagation.
   * @returns The list of requested order objects.
   */
  getOrders(filters: any = {}, options: HttpOptions=DEFAULT_HTTP_OPTIONS_NO_EXPIRE): Observable<Order[]> {
    if (!this._merchant) {
      console.log('Error: No merchant.');
      return of([]);
    }
    // If the orders are already loaded, return them
    const cacheParams: any = { merchantId: this._merchant._id, filters };
    if (options.cache && this._cacheService.has(this._cacheNamespace, 'orders', cacheParams)) {
      const orders: Order[] = this._cacheService.get(this._cacheNamespace, 'orders', cacheParams);
      if (options.forcePropagate) {
        this._orders.next(orders);
      }
      return of(orders);
    }
    // Otherwise, load the orders from the backend
    return this._httpClient.post<Order[]>(
      `${this._environmentService.getApiUrl()}/${environment.qart.apiVersion}/merchants/${this._merchant._id}/orders/search`,
      filters,
      {
        headers: new HttpHeaders().set('Content-Type', 'application/json'),
        withCredentials: true
      })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          console.error(`Error: ${error}`);
          return of([]);
        }),
        map((orders: Order[]) => orders.map(order => this.rebuildOrder(order))),
        // Handle the logic of the cache and the propagation
        tap((orders: Order[]) => {
          if (options.concatenateResults && this._orders.value != null) {
            orders = this._orders.value.concat(orders);
          }
          if (options.cache) {
            this._cacheService.set(this._cacheNamespace, 'orders', cacheParams, orders, options.expire);
          }
          if (options.propagate) {
            this._orders.next(orders);
          }
        })
      );
  }

  /**
   * Creates an order for the merchant.
   * @param order The order to be created.
   * @param options - The options related to cache and propagation.
   * @returns The created order object.
   */
  createOrder(order: Order, options: HttpOptions=DEFAULT_HTTP_OPTIONS_NO_EXPIRE): Observable<Order | null> {
    if (!this._merchant) {
      console.log('Error: No merchant.');
      return of(null);
    }
    const copyOrder: Order = cloneDeep(order);
    copyOrder.qartOrder = order.qartOrder.toJson();
    return this._httpClient.post<Order>(
      `${this._environmentService.getApiUrl()}/${environment.qart.apiVersion}/merchants/${this._merchant._id}/orders`,
      { order: copyOrder },
      {
        headers: new HttpHeaders().set('Content-Type', 'application/json'),
        withCredentials: true
      })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          const errorCode: string = error.error?.message || 'error-unknown';
          this._fuseConfirmationService.open({
            title: this._translocoService.translate(`${this._translocoRead}.${errorCode}.title`),
            message: this._translocoService.translate(`${this._translocoRead}.${errorCode}.message`),
            icon: {
                show: true,
                name: 'heroicons_outline:exclamation-triangle',
                color: 'warn'
            },
            actions: {
                confirm: {
                    show: true,
                    label: this._translocoService.translate(`${this._translocoRead}.${errorCode}.btn-close`),
                    color: 'warn'
                },
                cancel: {
                    show: false
                }
            },
            dismissible: true
          });
          return of(null);
        }),
        map((order: Order) => order ? this.rebuildOrder(order) : null),
        // Handle the logic of the cache and the propagation
        tap((order: Order) => {
          if (!order) {
            return;
          }
          // Delete all the cache entries that contain the counts (regardless of the filters)
          this._cacheService.delete(this._cacheNamespace, 'orderCount', null);
          // Update the cache entries for all the orders (regardless of the filters)
          this._cacheService.delete(this._cacheNamespace, 'orders', null);
          const orders: Order[] = this._orders.getValue() || [];
          if (options.cache) {
            this._cacheService.set(this._cacheNamespace, 'order', { merchantId: this._merchant._id, orderId: order._id }, order, options.expire);
          }
          if (options.propagate) {
            this._order.next(order);
            this._orders.next([order, ...orders]);
          }
        })
      );
  }

  /**
   * Updates an order.
   * @param order - The order to update.
   * @param options - The options related to cache and propagation.
   * @returns The updated order object.
   */
  updateOrder(order: Order, options: HttpOptions=DEFAULT_HTTP_OPTIONS_NO_EXPIRE): Observable<Order | null> {
    if (!this._merchant) {
      console.log('Error: No merchant.');
      return of(null);
    }
    const copyOrder: Order = cloneDeep(order);
    copyOrder.qartOrder = order.qartOrder.toJson();
    return this._httpClient.put<Order>(
      `${this._environmentService.getApiUrl()}/${environment.qart.apiVersion}/merchants/${this._merchant._id}/orders/${order._id}`,
      { order: copyOrder },
      {
        headers: new HttpHeaders().set('Content-Type', 'application/json'),
        withCredentials: true
      })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          console.log('Error:', error);
          return of(null);
        }),
        map((order: Order) => order ? this.rebuildOrder(order) : null),
        // Handle the logic of the cache and the propagation
        tap((order: Order) => {
          if (!order) {
            return;
          }
          // Delete all the cache entries that contain the counts (regardless of the filters)
          this._cacheService.delete(this._cacheNamespace, 'orderCount', null);
          // Update the cache entries for all the orders (regardless of the filters)
          this._cacheService.delete(this._cacheNamespace, 'orders', null);
          const orders: Order[] = this._orders.getValue() || [];
          const orderIndex = orders.findIndex(o => o._id === order._id);
          if (orderIndex !== -1) {
            orders[orderIndex] = order;
          }
          if (options.cache) {
            this._cacheService.set(this._cacheNamespace, 'order', { merchantId: this._merchant._id, orderId: order._id }, order, options.expire);
          }
          if (options.propagate) {
            this._order.next(order);
            this._orders.next(orders);
          }
        })
      );
  }

  /**
   * Creates a payment intent for the given order.
   * @param order The order for which to create the payment intent.
   * @returns The created payment intent.
   */
  createPaymentIntent(order: Order): Observable<PaymentIntent> {
    if (!this._merchant) {
      return of(null);
    }
    const copyOrder: Order = cloneDeep(order);
    copyOrder.qartOrder = order.qartOrder.toJson();
    return this._httpClient.post<PaymentIntent>(
      `${this._environmentService.getApiUrl()}/${environment.qart.apiVersion}/merchants/${this._merchant._id}/stripe/payment-intent`,
      { order: copyOrder },
      {
        headers: new HttpHeaders().set('Content-Type', 'application/json'),
        withCredentials: true
      })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          console.log('Error:', error);
          console.log(error)
          return of(null);
        })
      );
  }

  /**
   * Updates the payment intent for the given order and payment intent ID.
   * @param order The order to update.
   * @param paymentIntentId The ID of the payment intent to update.
   * @returns The updated payment intent.
   */
  updatePaymentIntent(order: Order, paymentIntentId: string): Observable<PaymentIntent> {
    if (!this._merchant) {
      return of(null);
    }
    const copyOrder: Order = cloneDeep(order);
    copyOrder.qartOrder = order.qartOrder.toJson();
    return this._httpClient.put<PaymentIntent>(
      `${this._environmentService.getApiUrl()}/${environment.qart.apiVersion}/merchants/${this._merchant._id}/stripe/payment-intent/${paymentIntentId}`,
      { order: copyOrder },
      {
        headers: new HttpHeaders().set('Content-Type', 'application/json'),
        withCredentials: true
      })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          console.log('Error:', error);
          return of(null);
        })
      );
  }

  /**
   * Finalizes the current order by sending a PUT request to the API endpoint.
   * @param options - The options related to cache and propagation.
   * @returns The finalized Order object.
   */
  finalizeOrder(options: HttpOptions=DEFAULT_HTTP_OPTIONS_NO_EXPIRE): Observable<Order | null> {
    if (!this._merchant || !this._order.value) {
      console.error('Error: No merchant or no order.');
      return of(null);
    }
    return this._httpClient.put<Order>(
      `${this._environmentService.getApiUrl()}/${environment.qart.apiVersion}/merchants/${this._merchant._id}/orders/${this._order.value._id}/finalize`,
      {},
      {
        headers: new HttpHeaders().set('Content-Type', 'application/json'),
        withCredentials: true
      })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          console.log('Error:', error);
          return of(null);
        }),
        map((order: Order) => order ? this.rebuildOrder(order) : null),
        // Handle the logic of the cache and the propagation
        tap((order: Order) => {
          if (!order) {
            return;
          }
          // Delete all the cache entries that contain the counts (regardless of the filters)
          this._cacheService.delete(this._cacheNamespace, 'orderCount', null);
          // Update the cache entries for all the orders (regardless of the filters)
          this._cacheService.delete(this._cacheNamespace, 'orders', null);
          const orders: Order[] = this._orders.getValue() || [];
          const orderIndex = orders.findIndex(o => o._id === order._id);
          if (orderIndex !== -1) {
            orders[orderIndex] = order;
          }
          if (options.cache) {
            this._cacheService.set(this._cacheNamespace, 'order', { merchantId: this._merchant._id, orderId: order._id }, order, options.expire);
          }
          if (options.propagate) {
            this._order.next(order);
            this._orders.next(orders);
          }
        })
      );
  }

  /**
   * Returns the count of orders for the current merchant based on the provided filters.
   * @param filters - The filters to apply to the count query.
   * @param options - The options related to cache and propagation.
   * @returns The number of orders.
   */
  countOrders(filters: any = {}, options: HttpOptions=DEFAULT_HTTP_OPTIONS_NO_EXPIRE): Observable<number> {
    if (!this._merchant) {
      console.log('Error: No merchant.');
      return of(0);
    }
    // If the order count is already loaded, return them
    const cacheParams: any = { merchantId: this._merchant._id, filters };
    if (options.cache && this._cacheService.has(this._cacheNamespace, 'orderCount', cacheParams)) {
      const orderCount: number = this._cacheService.get(this._cacheNamespace, 'orderCount', cacheParams) || 0;
      if (options.forcePropagate) {
        this._orderCount.next(orderCount);
      }
      return of(orderCount);
    }
    // Otherwise, load the order count from the backend
    return this._httpClient.post<number>(
      `${this._environmentService.getApiUrl()}/${environment.qart.apiVersion}/merchants/${this._merchant._id}/orders/count`,
      filters,
      {
        headers: new HttpHeaders().set('Content-Type', 'application/json'),
        withCredentials: true
      })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          console.log('Error:', error);
          return of(0);
        }),
        // Handle the logic of the cache and the propagation
        tap((orderCount: number) => {
          if (options.cache) {
            this._cacheService.set(this._cacheNamespace, 'orderCount', cacheParams, orderCount, options.expire);
          }
          if (options.propagate) {
            this._orderCount.next(orderCount);
          }
        })
      );
  }

  /**
   * Returns the selected return rate for a given order.
   * @param order - The order to retrieve the selected return rate for.
   * @returns The selected return rate, or null if none is found.
   */
  getSelectedReturnRate(order: Order): ShippoShippingRate | null {
    // Retrieve the selected rate
    if (order.qartOrder?.shippingMethod === 'shippo') {
      let selectedReturnRate: ShippoShippingRate = null;
      const rateId: string = order.shipping?.shippo?.returnLabel?.rate;
      if (rateId) {
        const rates: any[] = order.shipping?.shippo?.returnShipment?.rates;
        if (rates) {
          selectedReturnRate = rates.find(rate => rate.object_id === rateId);
          // Compute the arrival date
          const shipmentDate: moment.Moment = moment(order.shipping?.shippo?.returnShipment.shipment_date);
          if (selectedReturnRate.estimated_days) {
            selectedReturnRate.arrives_by = shipmentDate.add(selectedReturnRate.estimated_days, 'day').toISOString();
          }
        }
      }
      return selectedReturnRate;
    } else if (order.qartOrder?.shippingMethod === 'flat') {
      return null;
    }
    return null;
  }

  /**
   * Cancels an order.
   * @param orderId - The ID of the order to be cancelled.
   * @param options - The options related to cache and propagation.
   * @returns The cancelled Order object.
   */
  cancelOrder(orderId: string, options: HttpOptions=DEFAULT_HTTP_OPTIONS_NO_EXPIRE): Observable<Order | null> {
    if (!this._merchant) {
      console.log('Error: No merchant.');
      return of(null);
    }
    return this._httpClient.post<Order>(
      `${this._environmentService.getApiUrl()}/${environment.qart.apiVersion}/merchants/${this._merchant._id}/orders/${orderId}/cancel`,
      {},
      {
        headers: new HttpHeaders().set('Content-Type', 'application/json'),
        withCredentials: true
      })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          console.log('Error:', error);
          return of(null);
        }),
        map((order: Order) => order ? this.rebuildOrder(order) : null),
        // Handle the logic of the cache and the propagation
        tap((order: Order) => {
          if (!order) {
            return;
          }
          // Delete all the cache entries that contain the counts (regardless of the filters)
          this._cacheService.delete(this._cacheNamespace, 'orderCount', null);
          // Update the cache entries for all the orders (regardless of the filters)
          this._cacheService.delete(this._cacheNamespace, 'orders', null);
          const orders: Order[] = this._orders.getValue() || [];
          const orderIndex = orders.findIndex(o => o._id === order._id);
          if (orderIndex !== -1) {
            orders[orderIndex] = order;
          }
          if (options.cache) {
            this._cacheService.set(this._cacheNamespace, 'order', { merchantId: this._merchant._id, orderId: order._id }, order, options.expire);
          }
          if (options.propagate) {
            this._order.next(order);
            this._orders.next(orders);
          }
        })
      );
  }

  /**
   * Requests a refund for a product order within an order.
   * @param orderId - The ID of the order.
   * @param productOrderId - The ID of the product order.
   * @param quantity - The quantity of the product to be refunded.
   * @param metadata - Additional metadata to be included with the refund request.
   * @param options - The options related to cache and propagation.
   * @returns The updated order after the refund request is made.
   */
  requestRefund(
    orderId: string,
    productOrderId: string,
    quantity: number,
    metadata: any = {},
    options: HttpOptions=DEFAULT_HTTP_OPTIONS_NO_EXPIRE
  ): Observable<Order | null> {
    if (!this._merchant) {
      console.log('Error: No merchant.');
      return of(null);
    }
    return this._httpClient.post<Order>(
      `${this._environmentService.getApiUrl()}/${environment.qart.apiVersion}/merchants/${this._merchant._id}/orders/${orderId}/user_refund/${productOrderId}`,
      {
        quantity,
        metadata
      },
      {
        headers: new HttpHeaders().set('Content-Type', 'application/json'),
        withCredentials: true
      })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          console.log('Error:', error);
          return of(null);
        }),
        map((order: Order) => order ? this.rebuildOrder(order) : null),
        // Handle the logic of the cache and the propagation
        tap((order: Order) => {
          if (!order) {
            return;
          }
          // Delete all the cache entries that contain the counts (regardless of the filters)
          this._cacheService.delete(this._cacheNamespace, 'orderCount', null);
          // Update the cache entries for all the orders (regardless of the filters)
          this._cacheService.delete(this._cacheNamespace, 'orders', null);
          const orders: Order[] = this._orders.getValue() || [];
          const orderIndex = orders.findIndex(o => o._id === order._id);
          if (orderIndex !== -1) {
            orders[orderIndex] = order;
          }
          if (options.cache) {
            this._cacheService.set(this._cacheNamespace, 'order', { merchantId: this._merchant._id, orderId: order._id }, order, options.expire);
          }
          if (options.propagate) {
            this._order.next(order);
            this._orders.next(orders);
          }
        })
      );
  }

  /**
   * Sends a request to initiate a return for a given order.
   * @param orderId - The ID of the order to initiate a return for.
   * @param body - The request body containing the details of the return.
   * @param options - The options related to cache and propagation.
   * @returns The updated Order object if the request is successful.
   */
  requestReturn(orderId: string, body: any, options: HttpOptions=DEFAULT_HTTP_OPTIONS_NO_EXPIRE): Observable<Order | null> {
    if (!this._merchant || !this._order.value) {
      console.error('Error: No merchant or no order.');
      return of(null);
    }
    return this._httpClient.post<Order>(
      `${this._environmentService.getApiUrl()}/${environment.qart.apiVersion}/merchants/${this._merchant._id}/orders/${orderId}/user_return`,
      body,
      {
        headers: new HttpHeaders().set('Content-Type', 'application/json'),
        withCredentials: true
      })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          console.log('Error:', error);
          return of(null);
        }),
        map((order: Order) => order ? this.rebuildOrder(order) : null),
        // Handle the logic of the cache and the propagation
        tap((order: Order) => {
          if (!order) {
            return;
          }
          // Delete all the cache entries that contain the counts (regardless of the filters)
          this._cacheService.delete(this._cacheNamespace, 'orderCount', null);
          // Update the cache entries for all the orders (regardless of the filters)
          this._cacheService.delete(this._cacheNamespace, 'orders', null);
          const orders: Order[] = this._orders.getValue() || [];
          const orderIndex = orders.findIndex(o => o._id === order._id);
          if (orderIndex !== -1) {
            orders[orderIndex] = order;
          }
          if (options.cache) {
            this._cacheService.set(this._cacheNamespace, 'order', { merchantId: this._merchant._id, orderId: order._id }, order, options.expire);
          }
          if (options.propagate) {
            this._order.next(order);
            this._orders.next(orders);
          }
        })
      );
  }

  /**
   * Returns the selected shipping rate for the given order.
   * @param order - The order to retrieve the selected rate from.
   * @returns The selected shipping rate, or null if no rate is selected.
   */
  getSelectedRate(order: Order): ShippoShippingRate | FlatShippingRate | null {
    // Retrieve the selected rate
    if (order.qartOrder?.shippingMethod === 'shippo') {
      let selectedRate: ShippoShippingRate | null = null;
      const rateId: string | null = order.shipping?.shippo?.shippingLabel?.rate || null;
      if (rateId) {
        const rates: any[] = order.shipping?.shippo?.shipment?.rates;
        if (rates) {
          selectedRate = rates.find(rate => rate.object_id === rateId);
          // Compute the arrival date
          const shipmentDate: moment.Moment = moment(order.shipping?.shippo?.shipment.shipment_date);
          if (selectedRate?.estimated_days) {
            selectedRate.arrives_by = shipmentDate.add(selectedRate.estimated_days, 'day').toISOString();
          }
        }
      }
      return selectedRate;
    } else if (order.qartOrder?.shippingMethod === 'flat') {
      const selectedRate: FlatShippingRate | null = order.qartOrder.flatShippingRate;
      return selectedRate;
    }
    return null;
  }

  /**
   * Determines if an order is shippable based on its product orders.
   * @param order - The order to check.
   * @returns True if the order is shippable, false otherwise.
   */
  isShippableOrder(order: Order): boolean {
    return order.qartOrder.productOrders?.some(productOrder => productOrder.product.type === ProductType.Product && productOrder.product.shippable) ?? false;
  }

  /**
   * Checks if the given order has a mix of shippable and non-shippable products.
   * @param order - The order to check.
   * @returns True if the order has both shippable and non-shippable products, false otherwise.
   */
  hasMixedBasket(order: Order): boolean {
    const hasShippableProducts: boolean = order.qartOrder.productOrders
      .some(productOrder => productOrder.product.type === ProductType.Product && productOrder.product.shippable);
    const hasNonShippableProducts: boolean = order.qartOrder.productOrders
      .some(productOrder => !productOrder.product.shippable);
    return hasShippableProducts && hasNonShippableProducts;
  }

  /**
   * Checks if the given order contains only services and no products.
   * @param order - The order to check.
   * @returns True if the order contains only services, false otherwise.
   */
  hasOnlyServices(order: Order): boolean {
    const hasServices: boolean = order.qartOrder.productOrders.some(productOrder => productOrder.product.type === ProductType.Service);
    const hasProducts: boolean = order.qartOrder.productOrders.some(productOrder => productOrder.product.type === ProductType.Product);
    return hasServices && !hasProducts;
  }

  /**
   * Checks if the given order contains only products (no services).
   * @param order - The order to check.
   * @returns True if the order contains only products, false otherwise.
   */
  hasOnlyProducts(order: Order): boolean {
    const hasServices: boolean = order.qartOrder.productOrders.some(productOrder => productOrder.product.type === ProductType.Service);
    const hasProducts: boolean = order.qartOrder.productOrders.some(productOrder => productOrder.product.type === ProductType.Product);
    return !hasServices && hasProducts;
  }

  /**
   * Checks if a product order is refundable based on its type and status.
   * @param productOrder - The product order to check.
   * @param order - The order that the product order belongs to.
   * @returns A boolean indicating whether the product order is refundable or not.
   */
  isRefundable(productOrder: ProductOrder, order: Order): boolean {
    if (productOrder.product.type === ProductType.Service) {
      // Check if it is too late or not
      let refundDeadline: moment.Moment = moment(productOrder.selectedServiceTimeSlot);
      if (productOrder.product.leadTime.type === 'relative') {
        const duration: number = productOrder.product.leadTime.duration;
        refundDeadline = moment(refundDeadline).subtract(duration, 'millisecond');
      }
      return refundDeadline.isAfter(moment());
    } else if (productOrder.product.type === ProductType.Product) {
      /*
      if (productOrder.product.leadTime.type === 'relative') {
        // Check if it is too late or not
        const duration: number = productOrder.product.leadTime.duration;
        return moment(order.qartOrder.pickupDate).subtract(duration, 'millisecond').isAfter(moment());
      } 
      */
      const statesRefundAllowed: string[] = ['to-be-collected', 'to-be-shipped', 'refund-requested'];
      if (productOrder.status === 'refund_requested' || !statesRefundAllowed.includes(order.status)) {
        return false;
      }
    }
    return true;
  }

  /**
   * Computes the estimated delivery date for an order based on the order's payment date, product lead times, and shipping rate.
   * @param order - The order for which to compute the estimated delivery date.
   * @param settings - The settings used to compute the estimated delivery date.
   * @param rate - The shipping rate used to compute the estimated delivery date.
   * @returns The estimated delivery date for the order.
   */
  computeEstimatedDeliveryDate(order: Order, settings: Settings, rate?: ShippoShippingRate | FlatShippingRate): Date {
    const eventPayment = order.events?.history.find(event => event.event === 'payment_succeeded');
    // Get the date at which the order was created
    let orderPaymentDate: Date;
    if (eventPayment) {
      orderPaymentDate = new Date(eventPayment.updatedAt);
    } else {
      orderPaymentDate = new Date(order.createdAt);
    }
    // Add the sum of the lead time of all the products
    const sumProductOrderDuration: moment.Duration = moment.duration({
      millisecond: order.qartOrder.productOrders
        .map(productOrder => {
          if (productOrder.product.leadTime.type === 'relative') {
            return productOrder.product.leadTime.duration;
          } else {
            return 0;
          }
        })
        .reduce((acc, cur) => acc + cur, 0)
    });
    // Take the maximum with the calendar global lead time
    let minimumDuration: moment.Duration;
    if (settings) {
      const calendarBlockedDelayDuration: moment.Duration = moment.duration({ hour: settings.calendarBlockedDelay });
      minimumDuration = moment.duration({
        millisecond: Math.max(calendarBlockedDelayDuration.asMilliseconds(), sumProductOrderDuration.asMilliseconds())
      });
    } else {
      minimumDuration = sumProductOrderDuration;
    }
    // Add the estimated number of days for delivery
    let estimatedDeliveryDate: Date;
    if (rate && order.qartOrder?.shippingMethod === 'shippo') {
      rate = rate as ShippoShippingRate;
      if (rate.estimated_days) {
        estimatedDeliveryDate = moment(orderPaymentDate).add(minimumDuration, 'milliseconds').add(rate.estimated_days, 'days').toDate();
      }
    } else if (rate && order.qartOrder?.shippingMethod === 'flat') {
      rate = rate as FlatShippingRate;
      if (rate.applicableEstimatedDays != null) {
        estimatedDeliveryDate = moment(orderPaymentDate).add(minimumDuration, 'milliseconds').add(rate.applicableEstimatedDays, 'days').toDate();
      } else {
        estimatedDeliveryDate = moment(orderPaymentDate).add(minimumDuration, 'milliseconds').add(rate.estimatedDays, 'days').toDate();
      }
    } else {
      estimatedDeliveryDate = moment(orderPaymentDate).add(minimumDuration, 'milliseconds').toDate();
    }
    return estimatedDeliveryDate;
  }

  /**
   * Computes the minimum pickup date for an order based on the products and settings.
   * @param order - The order to compute the minimum pickup date for.
   * @param settings - The settings to use for computing the minimum pickup date.
   * @returns The minimum pickup date as a Date object.
   */
  computeMinimumPickupDate(order: Order, settings: Settings): Date {
    let products: Product[];
    if (order.qartOrder.deliveryMethod === 'collect') {
      products = order.qartOrder.productOrders
        .map(productOrder => productOrder.product)
        .filter(product => product.type === ProductType.Product);
    }
    else if (order.qartOrder.deliveryMethod === 'delivery') {
      products = order.qartOrder.productOrders
        .map(productOrder => productOrder.product)
        .filter(product => product.type === ProductType.Product)
        .filter(product => !product.shippable);
    }
    let minimumPickupDate: moment.Moment = moment();
    // Iterate until finding an available time
    minimumPickupDate = this._getMinimumAvailableDate(minimumPickupDate, products, settings);
    // Take the lead time into account
    const leadTimes = products
      .filter(product => product.leadTime.type === 'relative')
      .map(product => product.leadTime.duration);
    if (leadTimes.length > 0) {
      /*
        Take the maximum of the lead times
        const maxLeadTime = Math.max(...leadTimes)
        minimumPickupDate = moment(minimumPickupDate).add(maxLeadTime);
      */
      // Take the sum of the lead times
      for (let leadTime of leadTimes) {
        minimumPickupDate = moment(minimumPickupDate).add(leadTime);
      }
    }
    // Iterate until finding an available time
    minimumPickupDate = this._getMinimumAvailableDate(minimumPickupDate, products, settings);
    return minimumPickupDate.toDate();
  }
}



