import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CacheService } from '@core/cache/cache.service';
import { Customer } from '@core/customer/customer.types';
import { MerchantService } from '@core/merchant/merchant.service';
import { Merchant } from '@core/merchant/merchant.types';
import { ShippoAddress } from '@core/shipping/address-shippo.types';
import { UserService } from '@core/user/user.service';
import { EnvironmentService } from '@core/utils/environment/environment.service';
import { DEFAULT_HTTP_OPTIONS_NO_EXPIRE, HttpOptions } from '@core/utils/http-options.types';
import { environment } from '@env/environment';
import { PaymentMethod } from '@stripe/stripe-js';
import { User as FirebaseUser } from 'firebase/auth';
import { cloneDeep } from 'lodash';
import { BehaviorSubject, catchError, Observable, of, tap } from 'rxjs';


/**
 * Service for managing customer-related functionality.
 */
@Injectable({
  providedIn: 'root'
})
export class CustomerService {

  /**
   * The currently selected merchant.
   */
  private _merchant: Merchant | null = null;

  /**
   * The currently authenticated Firebase user.
   */
  private _user: FirebaseUser | null = null;

  /**
   * The cache namespace.
   */
  private _cacheNamespace: string = 'customer';

  /**
   * The offline customer object to use when the user is not authenticated.
   */
  private _offlineCustomer: Customer = {
    uid: null,
    stripeId: null,
    merchantId: null,
    name: null,
    email: null,
    emailVerified: false,
    phone: null,
    photoUrl: null,
    shippoAddresses: [],
    productsApproved: [],
    createdAt: new Date(),
    updatedAt: new Date()
  };

  /**
   * The currently selected customer, as a BehaviorSubject.
   */
  private _customer: BehaviorSubject<Customer | null> = new BehaviorSubject<Customer | null>(null);

  /**
   * Creates an instance of CustomerService.
   * @param {EnvironmentService} _environmentService - The environment service.
   * @param {HttpClient} _httpClient - The HTTP client service.
   * @param {MerchantService} _merchantService - The merchant service.
   * @param {UserService} _userService - The user service.
   * @param {CacheService} _cacheService - The cache service.
   */
  constructor(
    private _environmentService: EnvironmentService,
    private _httpClient: HttpClient,
    private _merchantService: MerchantService,
    private _userService: UserService,
    private _cacheService: CacheService
  ) {
    // Get the merchant
    this._merchantService.merchant$
      .subscribe((merchant: Merchant | null) => {
        const reload: boolean = this._merchant?._id.toString() !== merchant?._id.toString();
        this._merchant = merchant;
        if (reload) {
          this._offlineCustomer.merchantId = this._merchant._id.toString();
          this.getCurrentCustomer().subscribe();
        }
      });


    // Get the user
    this._userService.user$
      .subscribe((user: FirebaseUser | null) => {
        this._user = user;
        // We need to wait a bit, otherwise the function gets called as soon as the user is authenticated on Firebase,
        // but the sign-in procedure did not even have the time to record the access token
        setTimeout(async () => {
          this.getCurrentCustomer().subscribe();
        }, 1000);
      });
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Returns an observable of the current customer or null if there is no customer.
   * @returns An observable of the current customer or null if there is no customer.
   */
  get customer$(): Observable<Customer | null> {
    return this._customer.asObservable();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Get the current customer or null if there is no authenticated user.
   * If the user is not authenticated, it returns the offline customer.
   * @param options - The options related to cache and propagation.
   * @returns The current customer or null.
   */
  getCurrentCustomer(options: HttpOptions=DEFAULT_HTTP_OPTIONS_NO_EXPIRE): Observable<Customer | null> {
    if (!this._merchant) {
      console.log('Error: No merchant.');
      return of(null);
    }
    if (!this._user) { // If the user is not authenticated, we return the offline customer
      if (options.forcePropagate) {
        this._customer.next(this._offlineCustomer);
      }
      return of(this._offlineCustomer);
    }
    // If the customer is already loaded, return it.
    // If the customer is not found, do not record the cache miss yet.
    const cacheParams: any = { merchantId: this._merchant._id };
    if (options.cache) {
      if (this._cacheService.has(this._cacheNamespace, 'customer', cacheParams)) {
        const customer: Customer = this._cacheService.get(this._cacheNamespace, 'customer', cacheParams);
        if (options.forcePropagate) {
          this._customer.next(customer);
        }
        return of(customer);
      }
    }
    // Otherwise, load the customer from the backend
    return this._httpClient.get<Customer>(
      `${this._environmentService.getApiUrl()}/${environment.qart.apiVersion}/merchants/${this._merchant._id}/customers/${this._user.uid}`,
      {
        headers: new HttpHeaders().set('Content-Type', 'application/json'),
        withCredentials: true
      })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          console.error(`Error: ${error}`);
          return of(null);
        }),
        // Handle the logic of the cache and the propagation
        tap((customer: Customer) => {
          if (options.cache) {
            this._cacheService.set(this._cacheNamespace, 'customer', cacheParams, customer, options.expire);
          }
          if (options.propagate) {
            this._customer.next(customer);
          }
        })
      );
  }

  /**
   * Adds a new customer.
   * If the user is not authenticated, it returns the offline customer.
   * @param options - The options related to cache and propagation.
   * @returns The newly added customer.
   */
  addCustomer(options: HttpOptions=DEFAULT_HTTP_OPTIONS_NO_EXPIRE): Observable<Customer | null> {
    if (!this._merchant) {
      console.log('Error: No merchant.');
      return of(null);
    }
    if (!this._user) { // If the user is not authenticated, we return the offline customer
      if (options.forcePropagate) {
        this._customer.next(this._offlineCustomer);
      }
      return of(this._offlineCustomer);
    }
    const customerEmail = this._user.email;
    if ((customerEmail == null) || (customerEmail === '')) {
      console.log('The email is invalid.');
      return of(null);
    }
    const newCustomer: Customer = {
      uid: this._user.uid,
      stripeId: 'none',
      merchantId: this._merchant._id,
      name: this._user.displayName,
      email: this._user.email,
      emailVerified: this._user.emailVerified,
      phone: this._user.phoneNumber,
      photoUrl: this._user.photoURL,
      shippoAddresses: [],
      productsApproved: []
    };
    return this._httpClient.post<Customer>(
      `${this._environmentService.getApiUrl()}/${environment.qart.apiVersion}/merchants/${this._merchant._id}/customers`,
      { newCustomer },
      {
        headers: new HttpHeaders().set('Content-Type', 'application/json'),
        withCredentials: true
      })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          console.log('Error:', error);
          return of(null);
        }),
        // Handle the logic of the cache and the propagation
        tap((updatedCustomer: Customer) => {
          if (!updatedCustomer) {
            return;
          }
          if (options.cache) {
            this._cacheService.set(this._cacheNamespace, 'customer', { merchantId: this._merchant._id }, updatedCustomer, options.expire);
          }
          if (options.propagate) {
            this._customer.next(updatedCustomer);
          }
        })
      );
  }

  /**
   * Updates the customer information.
   * If the user is not authenticated, the offline customer is updated.
   * If the user is authenticated, the customer information is updated on the server.
   * @param newCustomer The updated customer information.
   * @param options - The options related to cache and propagation.
   * @returns The updated customer.
   */
  updateCustomer(newCustomer: Customer, options: HttpOptions=DEFAULT_HTTP_OPTIONS_NO_EXPIRE): Observable<Customer | null> {
    if (!this._merchant) {
      console.log('Error: No merchant.');
      return of(null);
    }
    if (!this._user) { 
      this._offlineCustomer = cloneDeep(newCustomer);
      if (options.forcePropagate) {
        this._customer.next(this._offlineCustomer);
      }
      return of(this._offlineCustomer);
    }
    newCustomer.merchantId = this._merchant._id;
    return this._httpClient.put<Customer>(
      `${this._environmentService.getApiUrl()}/${environment.qart.apiVersion}/merchants/${newCustomer.merchantId}/customers/${newCustomer._id}`,
      { newCustomer },
      {
        headers: new HttpHeaders().set('Content-Type', 'application/json'),
        withCredentials: true
      })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          console.log('Error:', error);
          return of(null);
        }),
        // Handle the logic of the cache and the propagation
        tap((updatedCustomer: Customer) => {
          if (!updatedCustomer) {
            return;
          }
          if (options.cache) {
            this._cacheService.set(this._cacheNamespace, 'customer', { merchantId: this._merchant._id }, updatedCustomer, options.expire);
          }
          if (options.propagate) {
            this._customer.next(updatedCustomer);
          }
        })
      );
  }

  /**
   * Gets the list of payment methods of the current customer.
   * @param type - The payment method type.
   * @param options - The options related to cache and propagation.
   * @returns The list of payment methods of the current customer.
   */
  getPaymentMethods(type: string, options: HttpOptions=DEFAULT_HTTP_OPTIONS_NO_EXPIRE): Observable<PaymentMethod[]> {
    if (!this._merchant) {
      console.log('Error: No merchant.');
      return of([]);
    }
    // If the payment method is already loaded, return it.
    // If the payment method is not found, do not record the cache miss yet.
    const cacheParams: any = { merchantId: this._merchant._id, type };
    if (options.cache) {
      if (this._cacheService.has(this._cacheNamespace, 'payment-methods', cacheParams)) {
        this._cacheService.updateStats(true);
        const paymentMethods: PaymentMethod[] = this._cacheService.get(this._cacheNamespace, 'payment-methods', cacheParams);
        return of(paymentMethods);
      }
    }
    // Otherwise, load the customer from the backend
    return this._httpClient.get<PaymentMethod[]>(
      `${this._environmentService.getApiUrl()}/${environment.qart.apiVersion}/merchants/${this._merchant._id}/customers/payment-methods/${type}`,
      {
        headers: new HttpHeaders().set('Content-Type', 'application/json'),
        withCredentials: true
      })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          console.log('Error:', error);
          return of([]);
        }),
        // Handle the logic of the cache and the propagation
        tap((paymentMethods: PaymentMethod[]) => {
          if (options.cache) {
            this._cacheService.set(this._cacheNamespace, 'payment-methods', cacheParams, paymentMethods, options.expire);
          }
        })
      );
  }

  /**
   * Creates a setup intent that accepts the given types of payment methods.
   * @param types A list of payment method types.
   * @param description An optional description for the setup intent.
   * @returns The created setup intent.
   */
  createSetupIntent(types: string[], description: string = ''): Observable<any> {
    if (!this._merchant) {
      console.log('Error: No merchant.');
      return of(null);
    }
    return this._httpClient.post<any>(
      `${this._environmentService.getApiUrl()}/${environment.qart.apiVersion}/merchants/${this._merchant._id}/customers/setup-intents`,
      { types, description },
      {
        headers: new HttpHeaders().set('Content-Type', 'application/json'),
        withCredentials: true
      })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          console.log('Error:', error);
          return of(null);
        })
      );
  }

  /**
   * Attaches a payment method to the current customer.
   * @param paymentMethodId The ID of the payment method to attach.
   * @param options - The options related to cache and propagation.
   * @returns The attached PaymentMethod object.
   */
  attachPaymentMethod(paymentMethodId: string, options: HttpOptions=DEFAULT_HTTP_OPTIONS_NO_EXPIRE): Observable<PaymentMethod> {
    if (!this._merchant) {
      console.log('Error: No merchant.');
      return of(null);
    }
    return this._httpClient.post<PaymentMethod>(
      `${this._environmentService.getApiUrl()}/${environment.qart.apiVersion}/merchants/${this._merchant._id}/customers/attach-payment-method`,
      { paymentMethodId },
      {
        headers: new HttpHeaders().set('Content-Type', 'application/json'),
        withCredentials: true
      })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          console.log('Error:', error);
          return of(null);
        }),
        // Handle the logic of the cache and the propagation
        tap((addedPaymentMethod: PaymentMethod) => {
          if (!addedPaymentMethod) {
            return;
          }
          if (options.cache) {
            this._cacheService.delete(this._cacheNamespace, 'payment-methods', null);
            this._cacheService.delete(this._cacheNamespace, 'customer', null);
            this.getCurrentCustomer().subscribe();
          }
        })
      );
  }

  /**
   * Detaches a payment method from the current customer.
   * @param paymentMethodId - The ID of the payment method to detach.
   * @param options - The options related to cache and propagation.
   * @returns The detached PaymentMethod object.
   */
  detachPaymentMethod(paymentMethodId: string, options: HttpOptions=DEFAULT_HTTP_OPTIONS_NO_EXPIRE): Observable<PaymentMethod> {
    if (!this._merchant) {
      console.log('Error: No merchant.');
      return of(null);
    }
    return this._httpClient.post<PaymentMethod>(
      `${this._environmentService.getApiUrl()}/${environment.qart.apiVersion}/merchants/${this._merchant._id}/customers/detach-payment-method`,
      { paymentMethodId },
      {
        headers: new HttpHeaders().set('Content-Type', 'application/json'),
        withCredentials: true
      })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          console.log('Error:', error);
          return of(null);
        }),
        // Handle the logic of the cache and the propagation
        tap((deletedPaymentMethod: PaymentMethod) => {
          if (!deletedPaymentMethod) {
            return;
          }
          if (options.cache) {
            this._cacheService.delete(this._cacheNamespace, 'payment-methods', null);
            this._cacheService.delete(this._cacheNamespace, 'customer', null);
          }
          this.getCurrentCustomer().subscribe();
        })
      );
  }

  /**
   * Creates a new address for the customer.
   * If the user is not authenticated, the address is added to the offline customer.
   * If the user is authenticated, the address is added to the customer on the server.
   * @param shippoAddress The ShippoAddress object containing the address information.
   * @param options - The options related to cache and propagation.
   * @returns The created ShippoAddress object.
   */
  createAddress(shippoAddress: ShippoAddress, options: HttpOptions=DEFAULT_HTTP_OPTIONS_NO_EXPIRE): Observable<ShippoAddress | null> {
    if (!this._merchant) {
      console.log('Error: No merchant.');
      return of(null);
    }
    if (!this._user) { 
      this._offlineCustomer.shippoAddresses.push(cloneDeep(shippoAddress));
      if (options.forcePropagate) {
        this._customer.next(this._offlineCustomer);
      }
      this.getCurrentCustomer().subscribe();
      return of(shippoAddress);
    }
    return this._httpClient.post<ShippoAddress>(
      `${this._environmentService.getApiUrl()}/${environment.qart.apiVersion}/merchants/${this._merchant._id}/customers/address`,
      { shippoAddress },
      {
        headers: new HttpHeaders().set('Content-Type', 'application/json'),
        withCredentials: true
      })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          console.log('Error:', error);
          return of(null);
        }),
        // Handle the logic of the cache and the propagation
        tap((shippoAddress: ShippoAddress) => {
          if (!shippoAddress) {
            return;
          }
          if (options.cache) {
            this._cacheService.delete(this._cacheNamespace, 'payment-methods', null);
            this._cacheService.delete(this._cacheNamespace, 'customer', null);
          }
          this.getCurrentCustomer().subscribe();
        })
      );
  }

  /**
   * Deletes a customer address with the given Shippo address ID.
   * If the user is not authenticated, the offline customer is updated.
   * @param shippoAddressId - The ID of the Shippo address to be deleted.
   * @param options - The options related to cache and propagation.
   * @returns The updated customer object.
   */
  deleteAddress(shippoAddressId: string, options: HttpOptions=DEFAULT_HTTP_OPTIONS_NO_EXPIRE): Observable<Customer | null> {
    if (!this._merchant) {
      console.log('Error: No merchant.');
      return of(null);
    }
    if (!this._user) { // If the user is not authenticated, we update the offline customer
      this._offlineCustomer.shippoAddresses = this._offlineCustomer.shippoAddresses.filter((shippoAddress: ShippoAddress) => shippoAddress._id.toString() !== shippoAddressId);
      if (options.forcePropagate) {
        this._customer.next(this._offlineCustomer);
      }
      return of(this._offlineCustomer);
    }
    return this._httpClient.delete<Customer>(
      `${this._environmentService.getApiUrl()}/${environment.qart.apiVersion}/merchants/${this._merchant._id}/customers/address/${shippoAddressId}`,
      {
        headers: new HttpHeaders().set('Content-Type', 'application/json'),
        withCredentials: true
      })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          console.log('Error:', error);
          return of(null);
        }),
        // Handle the logic of the cache and the propagation
        tap((updatedCustomer: Customer) => {
          if (!updatedCustomer) {
            return;
          }
          if (options.cache) {
            this._cacheService.set(this._cacheNamespace, 'customer', { merchantId: this._merchant._id }, updatedCustomer, options.expire);
          }
          if (options.propagate) {
            this._customer.next(updatedCustomer);
          }
        })
      );
  }

}


