import { Order } from "@core/order/order.types";
import { ProductOrder } from "@core/product/product-order.types";
import { SelectedAttribute } from "@core/product/product.types";
import { ShippoAddress } from "@core/shipping/address-shippo.types";
import { isEqual } from "lodash-es";


/**
 * Checks if two ShippoAddress objects are equal.
 * @param firstAddress - The first ShippoAddress object to compare.
 * @param secondAddress - The second ShippoAddress object to compare.
 * @returns A boolean indicating whether the two ShippoAddress objects are equal.
 */
export function isEqualShippoAddress(firstAddress: ShippoAddress, secondAddress: ShippoAddress): boolean {
    if (firstAddress?.object_id && secondAddress?.object_id) {
        return firstAddress.object_id === secondAddress.object_id;
    } else {
        return firstAddress?.street_no === secondAddress?.street_no &&
            firstAddress?.street1 === secondAddress?.street1 &&
            firstAddress?.city === secondAddress?.city &&
            firstAddress?.state === secondAddress?.state &&
            firstAddress?.zip === secondAddress?.zip &&
            firstAddress?.country === secondAddress?.country;
    }
}

/**
 * Checks if two orders have the same basket of products.
 * @param firstOrder - The first order to compare.
 * @param secondOrder - The second order to compare.
 * @returns True if both orders have the same basket of products, false otherwise.
 */
export function isEqualBasket(firstOrder: Order, secondOrder: Order): boolean {
    // The baskets are equal if both do not exist
    if (!firstOrder?.qartOrder?.productOrders && !secondOrder?.qartOrder?.productOrders) {
        return true;
    }
    // The baskets are different if one of them does not exist
    if (!firstOrder?.qartOrder?.productOrders || !secondOrder?.qartOrder?.productOrders) {
        return false;
    }
    // The baskets are different if they don't have the same number of products
    if (firstOrder.qartOrder?.productOrders.length !== secondOrder.qartOrder?.productOrders.length) {
        return false;
    }
    /**
     * Creates a hash string for the basket items of an order.
     * @param basket - The order to create the hash for.
     * @returns A hash string representing the basket items of the order.
     */
    const createBasketItemsHash = (basket: Order): string => {
        const hash: string = basket.qartOrder?.productOrders?.map((po: ProductOrder) => {
            const selectedVariant: string = po.selectedVariant.attributes.map((a: SelectedAttribute) => `${a.name}:${a.option}`).join(',');
            const hash: string = `${po.id}-${po.product._id}-${selectedVariant}-${po.quantity}`;
            return hash;
        }).join(';');
        return hash;
    }
    const firstBasketHash: string = createBasketItemsHash(firstOrder);
    const secondBasketHash: string = createBasketItemsHash(secondOrder);
    return firstBasketHash === secondBasketHash;
}

/**
 * Flattens a nested object into a single-level object with dot notation keys.
 * @param obj - The object to flatten.
 * @returns The flattened object.
 */
function flattenNestedObject(obj: any): any {
    const isObject = (val: any): boolean => val && typeof val === 'object' && !Array.isArray(val);
    const addDelimiter = (a: string, b: string): string => a ? `${a}.${b}` : b;
    const paths = (obj: any = {}, head: string = ''): string[] => {
        return Object.entries(obj).reduce((product: string[], [key, value]: [string, any]) => {
            let fullPath: string = addDelimiter(head, key);
            if (isObject(value)) {
                return product.concat(paths(value, fullPath));
            }else {
                return product.concat([fullPath]);
            }
        }, []);
    };
    const getValue = (obj: any, path: string) => {
        const lastKey: string = path.split('.').slice(-1)[0];
        let o = obj;
        path.split('.').slice(0, -1).forEach((key) => o = o[key]);
        return o[lastKey];
    }
    let response: any = {};
    paths(obj).forEach((path: string) => (response[path] = getValue(obj, path)));
    return response;
}


/**
 * Checks if all key-value pairs in obj1 are included in obj2.
 * @param obj1 - The first object to compare.
 * @param obj2 - The second object to compare.
 * @returns True if all key-value pairs in obj1 are included in obj2, false otherwise.
 */
export function isObjectIncludedInObject(obj1: any, obj2: any): boolean {
    const obj1Flattened: any = flattenNestedObject(obj1);
    const obj2Flattened: any = flattenNestedObject(obj2);
    return Object.keys(obj1Flattened).every((key: string) => isEqual(obj2Flattened[key], obj1Flattened[key]));
}

