
import { Product, ProductType } from '@core/product/product.types';
import { ProductOrder } from '../product/product-order.types';
import { FlatShippingRate } from '../shipping/shipping-rate-flat.types';
import { ShippoShippingRate } from '../shipping/shipping-rate-shippo.types';
import { Deserializable } from '../utils/deserializable.types';
import { Coupon } from '@core/coupon/coupon.types';


/**
 * Represents an order in the shopping cart.
 */
export class QartOrder implements Deserializable {

    /**
     * The unique identifier of the order.
     */
    public id: string;

    /**
     * The source of the order.
     */
    public source: string;

    /**
     * The list of product orders in the order.
     */
    public productOrders: ProductOrder[];

    /**
     * The delivery method of the order.
     */
    public deliveryMethod: string;

    /**
     * The shipping method of the order.
     */
    public shippingMethod: 'shippo' | 'flat';

    /**
     * The shipping rate for the order using Shippo.
     */
    public shippoShippingRate: ShippoShippingRate;

    /**
     * The flat shipping rate for the order.
     */
    public flatShippingRate: FlatShippingRate;

    /**
     * The percentage of shipping paid for the order.
     */
    public shippingPercentagePaid: number;

    /**
     * The pickup date for the order.
     */
    public pickupDate: Date;

    /**
     * The currency used for the order.
     */
    public currency: string;

    /** The coupon associated with the order. */
    public coupon: Coupon;

    /**
     * Creates a new instance of the QartOrder class.
     * @param qartOrder The QartOrder object to copy properties from.
     */
    constructor(qartOrder?: QartOrder) {
        if (!qartOrder) {
            this.source = 'merchant_website';
            this.productOrders = [];
            this.deliveryMethod = 'collect';
            this.shippingMethod = null;
            this.shippoShippingRate = null;
            this.flatShippingRate = null;
            this.shippingPercentagePaid = 1;
            this.pickupDate = null;
            this.currency = 'EUR';
            this.coupon = null;
        } else {
            this.id = qartOrder.id;
            this.source = qartOrder.source;
            this.productOrders = qartOrder.productOrders.map(productOrder => new ProductOrder(null, productOrder));
            this.deliveryMethod = qartOrder.deliveryMethod;
            this.shippingMethod = qartOrder.shippingMethod;
            this.shippoShippingRate = qartOrder.shippoShippingRate;
            this.flatShippingRate = qartOrder.flatShippingRate;
            this.shippingPercentagePaid = qartOrder.shippingPercentagePaid;
            this.pickupDate = qartOrder.pickupDate;
            this.currency = qartOrder.currency;
            this.coupon = qartOrder.coupon;
        }
    }

    /**
     * Returns the total number of products in the order.
     * @returns {number} The total number of products in the order.
     */
    get numberOfProducts(): number {
        return this.productOrders.map(productOrder => productOrder.quantity).reduce((acc, cur) => acc + cur, 0);
    }

    /**
     * Returns a boolean indicating whether the order contains any shippable products.
     * @returns {boolean} True if the order contains at least one shippable product, false otherwise.
     */
    get isShippable(): boolean {
        return this.productOrders.filter(productOrder => productOrder.product.shippable).length > 0;
    }

    /**
     * Returns the subtotal of the order by summing up the prices of all product orders.
     * @returns The subtotal of the order.
     */
    get subtotal(): number {
        return this.productOrders.map(productOrder => productOrder.price).reduce((acc, cur) => acc + cur, 0);
    }

    /**
     * Returns the total weight of all product orders in the QartOrder.
     * @returns {number} The total weight of all product orders.
     */
    get weight(): number {
        return this.productOrders.map(productOrder => productOrder.weight).reduce((acc, cur) => acc + cur, 0);
    }

    /**
     * The total cost of the order, including shipping.
     * @returns {number} The total cost of the order.
     */
    get total(): number {
        let totalAmount = this.subtotal;
        if (this.deliveryMethod === 'delivery') {
            if (this.shippingMethod === 'shippo') {
                if (this.shippoShippingRate?.amount) {
                    totalAmount += parseFloat(this.shippoShippingRate?.amount?.toString()) * this.shippingPercentagePaid;
                }
            } else if (this.shippingMethod === 'flat') {
                const flatShippingRateAmount = this.flatShippingRateAmount;
                if (flatShippingRateAmount) {
                    totalAmount += flatShippingRateAmount;
                }
            }
        }
        return totalAmount;
    }

    get couponDiscount(): number {
        let discount = 0;
        if (this.coupon) {
            let totalAmount = this.subtotal;
            if (this.coupon.params.discountType === 'percentage') {
                discount = totalAmount * (this.coupon.params.discount / 100);
            } else if (this.coupon.params.discountType === 'amount') {
                discount = this.coupon.params.discount;
            }
        }
        return discount;
    }

    /**
     * Returns the flat shipping rate amount for the order.
     * @returns {number} The flat shipping rate amount for the order.
     */
    get flatShippingRateAmount(): number {
        // A price of -1 means that the price is undefined
        if (!this.flatShippingRate) {
            return null;
        }
        if (!this.flatShippingRate.conditionsApply) {
            return this.flatShippingRate.price;
        }
        let prices: number[] = this.flatShippingRate.conditions.map(condition => {
            if (condition.criteria === 'nbItemsInBaskets') {
                if (this.numberOfProducts <= condition.threshold) {
                    return condition.priceBelowThreshold;
                } else if (this.numberOfProducts > condition.threshold) {
                    return condition.priceAboveThreshold;
                } else {
                    return -1;
                }
            } else if (condition.criteria === 'totalBasket') {
                if (this.subtotal < condition.threshold) {
                    return condition.priceBelowThreshold;
                } else if (this.subtotal >= condition.threshold) {
                    return condition.priceAboveThreshold;
                } else {
                    return -1;
                }
            } else if (condition.criteria === 'weightBasket') {
                const w: number = this.weight;
                const range: any = condition.ranges.find(r => r.min <= w && w <= r.max);
                if (range) {
                    return range.price;
                } else {
                    return -1;
                }
            }
        });
        for (let i = 0; i < prices.length; i++) {
            if (prices[i] == null) {
                prices[i] = -1;
            }
        }
        prices = prices.filter(price => price !== -1);
        if (prices.length === 0) {
            return -1;
        } else {
            return Math.min(...prices);
        }
    }

    /**
     * Deserializes the QartOrder object from the specified JSON object.
     * @param input The JSON object to deserialize.
     * @returns {this} The deserialized QartOrder object.
    */
    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }

    /** 
     * Returns the number of times the specified product is present in the order.
     * @param product The product to get the number of times it is present in the order.
     * @returns {number} The number of times the specified product is present in the order.
    */
    getNumberOfProduct(product: Product): number {
        return this.productOrders
            .filter(x => x.product._id === product._id)
            .map(x => x.quantity)
            .reduce((acc, cur) => acc + cur, 0);
    }
    
    /**
     * Merges the given QartOrder into the current QartOrder.
     * If a product order with the same product and variant already exists, the quantity is incremented.
     * Otherwise, the product order is added to the list.
     * @param qartOrder The QartOrder to merge into the current QartOrder.
     */
    merge(qartOrder: QartOrder) {
        qartOrder.productOrders
            .filter(productOrder => productOrder.product.type === ProductType.Product)
            .forEach(productOrder => {
                const existingProductOrder = this.productOrders
                    .find(po => po.product.type === ProductType.Product && po.selectedVariant?._id === productOrder.selectedVariant?._id);
                if (existingProductOrder) {
                    existingProductOrder.quantity += productOrder.quantity;
                } else {
                    this.productOrders.push(productOrder);
                }
            });
        // this.productOrders = this.productOrders.concat(qartOrder.productOrders);
        this.deliveryMethod = 'collect';
        this.shippingMethod = null;
        this.shippoShippingRate = null;
        this.flatShippingRate = null;
        this.shippingPercentagePaid = 1;
        this.pickupDate = null;
    }

    /**
     * Converts the QartOrder object to a JSON object.
     * @returns The QartOrder object as a JSON object.
     */
    toJson(): any {
        return {
            id: this.id,
            source: this.source,
            productOrders: this.productOrders.map(productOrder => productOrder.toJson()),
            deliveryMethod: this.deliveryMethod,
            shippingMethod: this.shippingMethod,
            shippoShippingRate: this.shippoShippingRate,
            flatShippingRate: this.flatShippingRate,
            flatShippingRateAmount: this.flatShippingRateAmount,
            shippingPercentagePaid: this.shippingPercentagePaid,
            pickupDate: this.pickupDate,
            numberOfProducts: this.numberOfProducts,
            isShippable: this.isShippable,
            subtotal: this.subtotal,
            total: this.total,
            weight: this.weight,
            currency: this.currency,
            coupon: this.coupon
        };
    }
}
